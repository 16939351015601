body {
  margin: 0;
  font-family: sans-serif;
}

#chart {
  background-color: #1f2232;
}

nav,
.settings {
  position: absolute;

  padding: 6px 6px 6px 9px;
  margin: 6px;

  border-radius: 6px;
  border-style: solid;

  background-color: #999;
  border-color: #999;
}

nav {
  font-size: 24px;
}

.settings {
  top: 55px;
  visibility: hidden;
}

.loading nav {
  display: none;
}

.handle {
  pointer-events: none;
}

:hover > .handle {
  display: none;
}

nav a {
  display: none;
  text-decoration: none;
}

nav:hover a {
  display: inline;
}

.stats,
.stats dt,
.stats dd {
  display: inline;

  font-size: 12px;

  vertical-align: middle;
}

.stats dd {
  margin-left: 0;
  margin-right: 4px;
}

.controls {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  pointer-events: none;
}

.controls-content {
  margin-right: 6px;
}

.controls a {
  display: inline;
  font-size: 24px;
  text-decoration: none;
  margin-left: 3px;
  pointer-events: all;
}

/* Add `.node` to override `.treemap-viz text`. */
.treemap-viz .node text {
  font-size: 16px;
  fill: #373737;
}

.treemap-viz .done-overlay {
  opacity: 0.35;
  pointer-events: none;
}

.treemap-viz .done-overlay text {
  visibility: hidden;
}
